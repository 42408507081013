<template>
    <div class="fill-height mask_grad">

        <v-container class="live mx-auto">
            <v-layout row mt-8>

                <v-flex style="position: relative;" class="sm12 ma-4 ma-md-0" :class="yt ? 'md8' : 'md9'">
                    <template v-if="playerLoading">
                        <v-skeleton-loader v-bind="attrs" style="width: 100%;" class="mb-6" type="text@1"></v-skeleton-loader>
                    </template>
                    <template v-else>
                        <v-layout row style="justify-content: flex-end;" mt-1 >
                            <v-flex md12>
                                <div class="live_ico"><svg viewBox="0 0 23 15"><use xlink:href="/assets/img/services/live.svg#view"></use></svg></div>
                                <p class="dist_title t1">{{$t('live_view')}} : {{liveDist}} </p>
                            </v-flex>
                            <!-- <v-flex md4 style="text-align: center" :style="$vuetify.rtl ? 'text-align: left' : 'text-align: right'">
                                <p v-if="yt" class="dist_stat" >{{statistics}} {{$t('views')}}</p>
                            </v-flex> -->
                        </v-layout>
                    </template>

                    <div class="player_wrap mt-2">
                        <v-skeleton-loader v-if="playerLoading" v-bind="attrs" type="image@3" ></v-skeleton-loader>
                        <video v-show="!playerLoading" ref="videoPlayer" class="video-js vjs-theme-dt"></video>
                    </div>

                    <template v-if="playerLoading">
                        <v-layout row>
                            <v-flex pa-3 sm4 class="comments"><v-skeleton-loader v-bind="attrs" type="image"></v-skeleton-loader></v-flex>
                            <v-flex pa-3 sm4 class="comments"><v-skeleton-loader v-bind="attrs" type="image"></v-skeleton-loader></v-flex>
                            <v-flex pa-3 sm4 class="comments"><v-skeleton-loader v-bind="attrs" type="image"></v-skeleton-loader></v-flex>
                        </v-layout>
                    </template>
                    
                    <template v-else>

                        <template v-if="yt" >
                            <v-flex sm12 mt-11 pb-sm-10 px-0 class="comments hidden-md-and-up">
                                <iframe id="videoObject" :src="'https://www.youtube.com/live_chat?v='+yt_src+'&embed_domain='+baseDomain" type="text/html" width="100%" height="100%" frameborder="0"></iframe>
                            </v-flex>
                        </template>

                        <template v-if="yt">
                            <!-- <p class="dist_title t1 hidden-md-and-up">{{$t('live_cameras')}}</p> -->
                            <v-layout row class="hidden-md-and-down">
                                <v-flex pa-3 md6 sm6 xs12 v-for="source in live" :key="source">
                                    <v-img @click="playSrc(source.src, source.pic, source.title, source.id)" min-height="55" class="cam_btn" :class="activeId === source.id ? 'cam_active' : ''" :style="$vuetify.rtl ? '' : 'padding: 15px 0 15px 25%;'">
                                        <!-- <div class="play hidden-sm-and-down"><img :class="source.id" :src="activeId === source.id ? '/assets/img/live/cam_play.svg' : '/assets/img/live/cam.svg'" /></div> -->
                                        <div class="play" :class="$vuetify.rtl ? '' : 'play_ltr'"><svg viewBox="0 0 35 25"><use xlink:href="assets/img/live/cam.svg#view"></use></svg></div>
                                        <span>{{source.title}}</span>
                                    </v-img>
                                </v-flex>
                            </v-layout>
                        </template>

                    </template>
                </v-flex>
                <!-- <v-img src="/assets/img/live/live_bg.png"></v-img> -->

                <template v-if="playerLoading">
                    <v-flex sm4 mt-11 pb-10 px-4 class="comments">
                        <v-skeleton-loader style="height:100%" v-bind="attrs" type="paragraph@9"></v-skeleton-loader>
                    </v-flex>
                </template>

                <template v-else>

                    <template v-if="yt" >
                        <v-flex sm12 md4 mt-11 px-4 class="comments hidden-sm-and-down">
                            <iframe id="videoObject" :src="'https://www.youtube.com/live_chat?v='+yt_src+'&embed_domain='+baseDomain" type="text/html" width="100%" height="100%" frameborder="0"></iframe>
                        </v-flex>
                    </template>
                    <template v-else>
                        <v-flex class="comments md3 sm12 hidden-sm-and-down">
                            
                            <p class="dist_title t1 ">{{$t('live_cameras')}}</p>

                            <v-layout row >
                                <v-flex pa-3 sm12 v-for="source in live" :key="source">
                                    <v-img @click="playSrc(source.src, source.pic, source.title, source.id)" min-height="55" class="cam_btn" :class="activeId === source.id ? 'cam_active' : ''" :style="$vuetify.rtl ? '' : 'padding: 15px 0 15px 25%;'" >
                                        
                                        <div class="play hidden-sm-and-down" :class="$vuetify.rtl ? '' : 'play_ltr'"><svg viewBox="0 0 35 25"><use xlink:href="assets/img/live/cam.svg#view"></use></svg></div>
                                        <span>{{source.title}}</span>
                                    </v-img>
                                </v-flex>
                            </v-layout>

                        </v-flex>
                    </template> 

                </template>
                
            </v-layout>
        </v-container>
        
    </div>
</template>

<script>
//  import axios from "axios";
import videojs from 'video.js';
import 'videojs-youtube';
import 'video.js/dist/video-js.min.css';
import '@digitaltheatre/videojs-theme-dt/dist/theme/index.css';
export default {
    name: 'VideoPlayer',
    data() {
        return {
            live:[],
            liveDist: '',
            activeId: 1,

            yt: false,
            yt_src: '',
            statistics: 0,
            
            baseDomain: window.location.hostname,

            playerLoading: true,
            axiosLoad: true,
            attrs: {
                class: 'sm-4',
                boilerplate: false,
                elevation: 2,
            },



            player: null,
            videoOptions: {
				autoplay: 'muted',
				// autoplay: false,
                poster: '',
                controlBar: {
                    remainingTimeDisplay: false,
                    currentTimeDisplay: false,
                    timeDivider: false,
                },
				controls: true,
                fill: true,
                autoSetup: false,
                techOrder: ['youtube', 'html5'],
                sources: [{ src: "https://stream.alkafeel.net/live/alkafeel/rAa5PGot1/manifest.m3u8", type: "application/x-mpegURL"}],
				// sources: [{ src: "https://www.youtube.com/watch?v="+this.yt_src, type: "video/youtube"}],
			}
        }
    },
    watch: {
        axiosLoad(n, o) {
            // console.log('axios not loading')
            if (!n && this.yt) {
                
                this.live_ini('yt');
                this.$refs.videoPlayer.load();
                setTimeout(() => this.loadPlayer(), 1000);
                setTimeout(() => this.playerLoading = false, 500);
            } else {
                
                this.live_ini('native');
                this.$refs.videoPlayer.load();
                setTimeout(() => this.loadPlayer(), 1000);
                setTimeout(() => this.playerLoading = false, 500);
            }
        }
    },
    beforeCreate() {
    },
    beforeDestroy() {
        if (this.player) {
            this.player.dispose();
        }
    },
    mounted () {
        // axios.get("https://www.googleapis.com/youtube/v3/search?part=snippet&channelId="+this.channelId+"&type=video&eventType=live&key="+this.API_KEY)
        // this.$axios.get("https://alkafeel.net/main_bg/data/ytb_api.json?t="+ Date.now())
        // .then(d => {
        //     if (d) {
        //         if (d.status === 200 && d.data.pageInfo.totalResults > 0) {
        //             this.yt_src = d.data.items[0].id.videoId;
        //             this.$axios.get('//www.youtube.com/embed/'+this.yt_src+'?origin=https://'+this.baseDomain)
        //             .then((data) => {
        //                 if (data.status === 200 && data.data.charAt(data.data.indexOf('videoDurationSeconds')+25) === '0') {
        //                     this.yt = true;
        //                     this.axiosLoad = false
        //                 } else {
        //                     this.axiosLoad = false
        //                 }
        //             });
                    
        //         } else {
        //             this.axiosLoad = false
        //         }
        //     } else {
        //         this.axiosLoad = false
        //     }
            
        // });

        this.$axios.get("https://alkafeel.net/main_bg/data/live_proxy.php?t="+ Date.now())
        .then(d => {
            if (d.status === 200 && d.data.live) {
                this.yt_src = d.data.videoId;
                this.yt = true;
                this.axiosLoad = false
            } else {
                this.axiosLoad = false
            }
        });


        // if (!this.axiosLoad) {
            
        //     setTimeout(() => this.loadPlayer(), 1000);
        //     setTimeout(() => this.playerLoading = false, 500);
        // }
        
    },
    methods: {
        live_ini(type) {

            this.$axios.get("live/all")
            .then((d) => {
                this.live = d.data;
                // console.log(type);
                if (type === 'yt') {
                    this.videoOptions.sources[0].src = '//www.youtube.com/watch?v='+this.yt_src; 
                    this.videoOptions.sources[0].type = 'video/youtube'
                } else {
                   this.videoOptions.sources[0].src = d.data[0]['src']; 
                   this.videoOptions.sources[0].type = 'application/x-mpegURL'
                }

                this.videoOptions.poster = 'https://alkafeel.net/live/images/'+d.data[0]['pic'];
                this.liveDist = d.data[0]['title'];
                this.activeId = d.data[0]['id'];

            });
        },
        playSrc(src, pic, title, id) {
            this.player.poster('https://alkafeel.net/live/images/'+pic);
            this.player.src({ type: "application/x-mpegURL", src: src });
            this.liveDist = title;
            this.activeId = id;
            this.player.play();
        },
        yt_src_fn() {
            this.$axios.get("https://www.googleapis.com/youtube/v3/search?part=snippet&channelId="+this.channelId+"&type=video&eventType=live&key="+this.API_KEY)
            .then(d => {
                if (d.status === 200 && d.data.pageInfo.totalResults > 0) {
                    let a = d.data.items[0].id.videoId;
                    this.yt = true;
                    this.yt_src = a;
                }
            });
        },
        getStatistics () {
            this.$axios.get("https://www.googleapis.com/youtube/v3/videos?id="+this.yt_src+"&part=statistics&key="+this.API_KEY)
            .then(d => {
                if (d.status === 200 && d.data.pageInfo.totalResults > 0) {
                    let a = d.data.items[0].statistics.viewCount;
                    this.statistics = a;
                }
            });
        },
        loadPlayer() {
            this.player = videojs(this.$refs.videoPlayer, this.videoOptions);
        }
    },
    // created () {
        
    // }
}
</script>

<style scoped>
    .cam_btn {
        background: #FFF;
        padding: 15px 25% 15px 0;
        cursor: pointer;
        box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.07);
        transition: .3s ease all;
        border-radius: 4px;
    }
    .cam_btn:hover {
        background: #B1BD52!important;
    }
    .cam_btn:hover .play{
        fill: #FFF!important;
    }
    .cam_active {
        background: #B1BD52!important;
    }
    .cam_active .play {
        fill: #FFF!important;
    }
    .mask_grad {
        background:url('/assets/img/live/live_bg.png'),linear-gradient(to bottom, rgba(177, 189, 82, 0.84) 0%, rgba(26, 26, 26, 0) 100%);
        background-repeat: repeat;
    }
    @keyframes changeColor {
        from {
            fill: rgba(177, 189, 82, 0.75);
        }
        to {
            fill: #F00;
        }
    }
    .live_ico {
        animation: changeColor 1s infinite alternate;
        width: 25px;
        height: 25px;
        margin: 0 10px 5px;
        display: inline-block;
    }
    #videoObject {
        min-height: 575px;
        border-radius: 5px;
        border: solid 2px #D4D4D4;
    }
    .live {
        width: 100%;
        padding-top: 30px;
        max-width: 1260px;
    }
    .player_wrap {
        width: 100% !important;
        height: 500px !important;
        margin-bottom: 25px;
    }
    @media (max-width: 600px) {
        .player_wrap {
            height: 300px !important;
        }
        .dist_title { 
            font-size: 16px!important
        }
    }
    @media (max-width: 420px) {
        .player_wrap {
            height: 200px !important;
        }
    }
    @media (max-width: 330px) {
        #videoObject {
            display: none;
        }
    }
    
    .play_ltr {
        left: calc(10% - 1px);
        right: auto!important;
        top: 10%!important;
        transform: rotate(180deg);
    }
    .play {
        position: absolute;
        top: calc(50% - 7px);
        right: calc(10% - 1px);
        width: 28px;
        fill:#B1BD52;
        transition: .3s ease all;
    }
    .play_side {
        position: absolute;
        top: calc(50% - 32px);
        right: calc(80% - 32px);
        width: 64px
    }
    .comments {
        padding: 0 10px;
    }
    .dist_title {
        font-size: 20px;
        display: inline-block;
        color: #000
    }
    .dist_stat {
        padding: 0 15px;
        font-size: 16px;
        color: #000
    }
    .vjs-control-bar {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%) !important;
    }
    .vjs-theme-dt {
        --color-primary:#B1BD52 !important;
    }
    .vjs-current-time .vjs-time-control .vjs-control, .vjs-time-divider {
        display: none!important;
    }
</style>